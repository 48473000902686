import React from "react";
import { Link } from "@reach/router";
import { AuthCheck } from "reactfire";
import AuthButton from "../Auth/Auth";
import { Risk, Add } from "grommet-icons";
import { Box, Header, Text, Anchor, ResponsiveContext } from "grommet";
import styles from "./Header.module.scss";

const Home = () => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Header
          pad={
            size !== "small"
              ? { horizontal: "xlarge", vertical: "small" }
              : { horizontal: "medium", vertical: "small" }
          }
          margin={{ vertical: "none" }}
          className={styles.root}
        >
          <Link to="/" className={styles.link}>
            <Box direction={"row"} align="center">
              <Risk className={styles.logo} color="dark-1" size="small" />
              <div></div>
              <Text
                size="large"
                margin={{ vertical: "none" }}
                className={styles.name}
              >
                {size !== "small" ? "BringToTable" : "BTT"}
              </Text>
            </Box>
          </Link>
          <Box direction={"row"} align="center">
            <AuthCheck fallback={false}>
              <Anchor
                size="small"
                label="game night"
                to="events/new"
                as={Link}
                icon={<Add size="small" />}
                className={styles.newEvent}
              />
            </AuthCheck>
            <AuthButton />
          </Box>
        </Header>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default Home;
