import React from "react";
import {
  Box,
  Image,
  Heading,
  Text,
  Anchor,
  Button,
  ResponsiveContext
} from "grommet";
import {
  Scorecard,
  Domain,
  Add,
  Trash,
  Checkmark,
  Clock,
  User
} from "grommet-icons";
import Rating from "../Rating/Rating";
import GameVote from "../GameVote/GameVote";
import getCategories from "../../util/getCategories";
import getMechanics from "../../util/getMechanics";
import styles from "./GameCard.module.scss";

const playTime = (minPlaytime, maxPlaytime) => {
  const min = parseInt(minPlaytime ?? 0, 10);
  const max = parseInt(maxPlaytime ?? 0, 10);

  if (min === 0 && max === 0) {
    return "Unknown";
  }

  if (min === max) {
    return min;
  }

  if (min > 0 && max > 0) {
    return `${min} - ${max}`;
  }

  if (min === 0) {
    return max;
  }

  if (max === 0) {
    return min;
  }
};

const renderBottomBar = (officialUrl, url, rulesUrl, size) => {
  if (!officialUrl && !rulesUrl && !url) {
    return;
  }

  const gameUrl = officialUrl || url;

  return (
    <Box
      direction="row"
      background="light-2"
      pad={
        size !== "small" ? { horizontal: "small", vertical: "xsmall" } : "small"
      }
    >
      {gameUrl && (
        <Anchor
          icon={<Domain size="small" />}
          size="xsmall"
          href={gameUrl}
          label="More info"
          target="_blank"
          margin={{ right: "small" }}
          className={styles.link}
        />
      )}
      {rulesUrl && (
        <Anchor
          icon={<Scorecard size="small" />}
          size="xsmall"
          href={rulesUrl}
          label="Rules"
          target="_blank"
          className={styles.link}
        />
      )}
    </Box>
  );
};

const GameCard = props => {
  const {
    name,
    images,
    imageSmall,
    minPlayers,
    maxPlayers,
    averageUserRating,
    officialUrl,
    rulesUrl,
    minPlaytime,
    maxPlaytime,
    onAddClick,
    onRemoveClick,
    hasGame,
    url,
    id,
    votes,
    invitationStatus,
    eventBaseRef,
    mechanics = [],
    categories = []
  } = props;
  const foundCategories = getCategories(categories);
  const foundMechanics = getMechanics(mechanics);

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          overflow="hidden"
          border={{
            color: "light-5",
            size: "xsmall"
          }}
          height="100%"
          justify="between"
          className={styles.root}
        >
          <Box direction="row" pad={size !== "small" ? "xsmall" : "small"}>
            <Box height="100px" width="100px">
              <Image src={images?.small ?? imageSmall} fit="contain" />
            </Box>
            <Box pad={{ horizontal: "small" }}>
              <Box direction="row" align="center" justify="between">
                <Box>
                  <Heading level="6" margin="none" className={styles.header}>
                    {name}
                  </Heading>
                  {votes && (
                    <GameVote
                      invitationStatus={invitationStatus}
                      votes={votes}
                      gameId={id}
                      eventBaseRef={eventBaseRef}
                    />
                  )}
                  <Rating
                    value={averageUserRating}
                    margin={{ vertical: "xsmall" }}
                  />
                  <Box>
                    <Box
                      pad={{ bottom: "xsmall" }}
                      round="xsmall"
                      margin={{ right: "small" }}
                      direction="row"
                      align="center"
                    >
                      <User size="small" />
                      <Text
                        color="dark-3"
                        size="xsmall"
                        margin={{ left: "xsmall" }}
                      >
                        {minPlayers}-{maxPlayers}
                      </Text>
                    </Box>
                    <Box round="xsmall" direction="row" align="center">
                      <Clock size="small" />
                      <Text
                        color="dark-3"
                        size="xsmall"
                        margin={{ left: "xsmall" }}
                      >
                        {playTime(minPlaytime, maxPlaytime)}
                      </Text>
                    </Box>
                    {foundCategories.length > 0 && (
                      <>
                        <Text size="xsmall">Categories</Text>
                        <Box direction="row" wrap>
                          {foundCategories.map(category => {
                            return (
                              <Box
                                round="small"
                                background="light-1"
                                direction="row"
                                pad="xsmall"
                                align="center"
                                key={category.id}
                                margin={{ bottom: "xsmall", right: "xsmall" }}
                              >
                                <Text size="xsmall">{category.name}</Text>
                              </Box>
                            );
                          })}
                        </Box>
                      </>
                    )}

                    {foundMechanics.length > 0 && (
                      <>
                        <Text size="xsmall">Mechanics</Text>
                        <Box direction="row" wrap>
                          {foundMechanics.map(mechanic => {
                            return (
                              <Box
                                round="small"
                                background="light-1"
                                direction="row"
                                pad="xsmall"
                                align="center"
                                key={mechanic.id}
                                margin={{ bottom: "xsmall", right: "xsmall" }}
                              >
                                <Text size="xsmall">{mechanic.name}</Text>
                              </Box>
                            );
                          })}
                        </Box>
                      </>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box>
            {renderBottomBar(officialUrl, url, rulesUrl, size)}

            {onAddClick && !hasGame && (
              <Button
                icon={<Add color="brand" size="small" />}
                size="small"
                className={styles.cta}
                onClick={() => {
                  onAddClick(props);
                }}
              />
            )}

            {hasGame && (
              <Text color="dark-3" size="small" className={styles.added}>
                Added <Checkmark size="small" />
              </Text>
            )}
            {onRemoveClick && (
              <Button
                className={styles.cta}
                icon={<Trash size="small" />}
                onClick={() => {
                  onRemoveClick(id);
                }}
              />
            )}
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};

GameCard.defaultProps = {
  averageUserRating: 0
};

export default GameCard;
