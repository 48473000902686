import React, { Suspense } from "react";
import { Box, Heading } from "grommet";
import Events from "../components/Events/Events";
import Groups from "../components/Groups/Groups";
import Loading from "../components/Loading/Loading";

const Home = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Box flex overflow="auto" gap="medium">
        <Box flex={false} direction="row-responsive" wrap>
          <Box flex="grow" margin="small">
            <Events status="invited" />
          </Box>
          <Box flex="grow" margin="small">
            <Heading level={2} margin={{ vertical: "small" }}>
              Game groups
            </Heading>
            <Groups />
          </Box>
        </Box>
      </Box>
    </Suspense>
  );
};

export default Home;
