import React, { Suspense } from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { useAuth, useFirestore } from "reactfire";
import { Text, Box } from "grommet";

const SignInForm = () => {
  const auth = useAuth();
  const firestore = useFirestore();
  const baseRef = firestore().collection("users");

  const uiConfig = {
    signInFlow: "popup",
    signInOptions: [auth.GoogleAuthProvider.PROVIDER_ID],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: ({ user }) => {
        const { displayName = "", photoURL = "", email = "" } = user;
        const userName = email.split("@")[0];

        let data = {
          displayName,
          photoURL,
          email,
          userName,
          uid: user.uid
        };

        baseRef
          .doc(user.uid)
          .set(data, { merge: true })
          .then(() => {
            window.location = "/";
          });
      }
    }
  };

  return <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth()} />;
};

const Login = props => {
  return (
    <Suspense fallback="">
      <Box justify="center" align="center" height="large">
        <Box
          justify="center"
          align="center"
          border={{
            color: "light-5",
            size: "xsmall"
          }}
          width="medium"
          pad="large"
          round="xsmall"
        >
          <Text>Login to continue</Text>
          <SignInForm />
        </Box>
      </Box>
    </Suspense>
  );
};

export default Login;
