import React from "react";
import {
  useFirestore,
  useFirestoreCollectionData,
  useUser,
  useFirebaseApp
} from "reactfire";
import GameCard from "../GameCard/GameCard";
import GamesGrid from "../GamesGrid/GamesGrid";
import GameFilter from "../GameFilter/GameFilter";

const Games = () => {
  const firestore = useFirestore();
  const firebaseApp = useFirebaseApp();
  const baseRef = firestore().collection("games");
  const user = useUser();
  const games = useFirestoreCollectionData(
    baseRef.where("userIds", "array-contains", user.uid),
    { idField: "id" }
  );

  const handleRemoveGame = id => {
    baseRef.doc(id).set(
      {
        userIds: firebaseApp.firestore.FieldValue.arrayRemove(user.uid)
      },
      {
        merge: true
      }
    );
  };

  return (
    <GameFilter games={games}>
      {filteredGames => (
        <GamesGrid>
          {filteredGames.map(game => {
            return (
              <GameCard
                {...game}
                key={game.id}
                onRemoveClick={handleRemoveGame}
              />
            );
          })}
        </GamesGrid>
      )}
    </GameFilter>
  );
};

export default Games;
