import React from "react";
import { Button, Box } from "grommet";
import { AuthCheck, useUser } from "reactfire";
import styles from "./AcceptDeclineEvent.module.scss";

const AcceptDeclineEvent = ({ baseRef, invitationStatus }) => {
  const user = useUser();
  let disableAccept = false;
  let disableDecline = false;

  if (invitationStatus === "accepted") {
    disableAccept = true;
  }

  if (invitationStatus === "declined") {
    disableDecline = true;
  }

  const handleAcceptInvite = () => {
    baseRef.set(
      {
        invited: {
          [user.uid]: "accepted"
        }
      },
      { merge: true }
    );
  };
  const handleDeclineInvite = () => {
    baseRef.set(
      {
        invited: {
          [user.uid]: "declined"
        }
      },
      { merge: true }
    );
  };

  return (
    <AuthCheck fallback={<div />}>
      <Box direction="row">
        <Button
          label="Going"
          onClick={handleAcceptInvite}
          disabled={disableAccept}
          className={styles.going}
        />

        <Button
          label="No-ing"
          onClick={handleDeclineInvite}
          disabled={disableDecline}
          className={styles.noing}
        />
      </Box>
    </AuthCheck>
  );
};

export default AcceptDeclineEvent;
