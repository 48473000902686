import React, { Suspense } from "react";
import UserInfo from "../../components/UserInfo/UserInfo";
import Games from "../../components/Games/Games";
import AddGame from "../../components/AddGame/AddGame";
import { useUser } from "reactfire";
import styles from "./Profile.module.scss";
import Loading from "../../components/Loading/Loading";
import { Tabs, Tab, Box } from "grommet";

const Profile = ({ navigate }) => {
  const user = useUser();

  if (!user) {
    navigate("/");
    return;
  }

  return (
    <Suspense fallback={<Loading />}>
      <div className={styles.root}>
        <Box margin={{ bottom: "large" }}>
          <UserInfo />
        </Box>
        <Tabs className={styles.tabs}>
          <Tab title="Your games">
            <Games />
          </Tab>
          <Tab title="Add games">
            <AddGame />
          </Tab>
        </Tabs>
      </div>
    </Suspense>
  );
};

export default Profile;
