import React from "react";
import { Menu, Text, Box } from "grommet";
import { Down } from "grommet-icons";
import { Link } from "@reach/router";
import Avatar from "../Avatar/Avatar";

const UserMenu = ({ user = {}, onSignOut }) => (
  <Menu
    dropAlign={{ top: "bottom", right: "right" }}
    icon={false}
    items={[
      {
        label: <Text size="small">Sign out</Text>,
        onClick: onSignOut
      },
      {
        label: (
          <Link to="profile">
            <Text size="small">Profile</Text>
          </Link>
        ),
        onClick: () => {}
      }
    ]}
    label={
      <Box direction="row" align="center">
        <Avatar name={user.displayName} url={user.photoURL} size="32px" />
        <Box margin={{ left: "xsmall" }}>
          <Down size="small" color="dark-1" />
        </Box>
      </Box>
    }
  />
);

export default UserMenu;
