export default {
  apiKey: "AIzaSyAh1wTWMJd9So5U2SByN84v7q8eV-X7kyA",
  authDomain: "gameknight-1266c.firebaseapp.com",
  databaseURL: "https://gameknight-1266c.firebaseio.com",
  projectId: "gameknight-1266c",
  storageBucket: "gameknight-1266c.appspot.com",
  messagingSenderId: "376981048001",
  appId: "1:376981048001:web:5bb9f20eb6819af9712240",
  measurementId: "G-NGSR2CBZ0F"
};
