import React from "react";
import { Box } from "grommet";
import { Risk } from "grommet-icons";
import styles from "./Loading.module.scss";

export const Loading = ({ inline }) => {
  let iconStyle = styles.icon;
  let iconSize = "xlarge";
  let align = "center";

  if (inline) {
    iconStyle += ` ${styles.inline}`;
    iconSize = "large";
    align = "start";
  }
  return (
    <Box align={align} justify={align} className={styles.root}>
      <Risk size={iconSize} color="brand" className={iconStyle} />
    </Box>
  );
};

export default Loading;
