import React from "react";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import GroupMember from "../GroupMember/GroupMember";

const GroupMembers = ({ members }) => {
  const queryLimit = 10;
  const memberUserIds = Object.keys(members);
  const firestore = useFirestore();
  const baseRef = firestore().collection("users");
  const limitedMemberIds = memberUserIds.slice(0, queryLimit);
  const membersData = useFirestoreCollectionData(
    baseRef.where("uid", "in", limitedMemberIds)
  );

  const andMore = memberUserIds.slice(queryLimit);

  return (
    <div>
      {membersData.map(({ displayName, photoURL, uid }) => (
        <GroupMember
          photoURL={photoURL}
          displayName={displayName}
          role={members[uid]}
          key={uid}
        />
      ))}

      {andMore.length > 0 && (
        <p>
          and <strong>{andMore.length}</strong> more
        </p>
      )}
    </div>
  );
};
export default GroupMembers;
