import React from "react";
import { Button } from "grommet";
import { AuthCheck, useUser, useFirestore, useFirebaseApp } from "reactfire";
import { Add, Logout } from "grommet-icons";

const JoinLeaveGroup = ({ group, groupId }) => {
  const user = useUser();
  const firestore = useFirestore();
  const firebaseApp = useFirebaseApp();
  const baseRef = firestore().collection("groups");

  const handleJoinGroup = () => {
    baseRef.doc(groupId).set(
      {
        members: {
          [user.uid]: "member"
        }
      },
      { merge: true }
    );
  };
  const handleLeaveGroup = () => {
    baseRef.doc(groupId).set(
      {
        members: {
          [user.uid]: firebaseApp.firestore.FieldValue.delete()
        }
      },
      { merge: true }
    );
  };

  return (
    <AuthCheck fallback={<div />}>
      {group.members[user?.uid] ? (
        <Button
          label="Leave group"
          onClick={handleLeaveGroup}
          icon={<Logout size="small" />}
          size="xsmall"
        />
      ) : (
        <Button
          label="Join group"
          onClick={handleJoinGroup}
          icon={<Add size="small" />}
        />
      )}
    </AuthCheck>
  );
};

export default JoinLeaveGroup;
