/*
 TODO: Stores this in a db and use a chron job or firestore function to update weekly from
 https://www.boardgameatlas.com/api/docs/game/mechanics
 */

export default [
  {
    id: "n1GtBt35Rd",
    name: "Acting",
    checked: false
  },
  {
    id: "PGjmKGi26h",
    name: "Action / Movement Programming",
    checked: false
  },
  {
    id: "oeg6wN9Eoc",
    name: "Action Point Allowance System",
    checked: false
  },
  {
    id: "Bc7R8pLoGk",
    name: "Action Selection",
    checked: false
  },
  {
    id: "05zCZoLvQJ",
    name: "Area Control",
    checked: false
  },
  {
    id: "3te2oybNR4",
    name: "Area Enclosure",
    checked: false
  },
  {
    id: "bgGxE0pI2B",
    name: "Area Movement",
    checked: false
  },
  {
    id: "amcImLdOmD",
    name: "Asymmetric",
    checked: false
  },
  {
    id: "AZxlPpi5oq",
    name: "Auction",
    checked: false
  },
  {
    id: "3tuJiW3pps",
    name: "Betting",
    checked: false
  },
  {
    id: "ZX3hYcF9H7",
    name: "Bluffing",
    checked: false
  },
  {
    id: "UEzHyBWtz8",
    name: "Building",
    checked: false
  },
  {
    id: "xuphiSlrxI",
    name: "Campaign",
    checked: false
  },
  {
    id: "iWODHwRGuU",
    name: "Card Drafting",
    checked: false
  },
  {
    id: "ebJKldFVeS",
    name: "Card Game",
    checked: false
  },
  {
    id: "ngCSHHk0H2",
    name: "Card Placement",
    checked: false
  },
  {
    id: "Gan96fffLL",
    name: "Chit-Pull System",
    checked: false
  },
  {
    id: "0ez69aUfuJ",
    name: "Communication Limits",
    checked: false
  },
  {
    id: "K0ykGhTMa8",
    name: "Comodity Speculation",
    checked: false
  },
  {
    id: "AAi6B8NLaV",
    name: "Confusion",
    checked: false
  },
  {
    id: "hwyCo1W0hi",
    name: "Conversation",
    checked: false
  },
  {
    id: "33UT4gTFqy",
    name: "Cooperative",
    checked: false
  },
  {
    id: "9mNukNBxfZ",
    name: "Cooperative Play",
    checked: false
  },
  {
    id: "xVDf5dmJts",
    name: "Crayon Rail System",
    checked: false
  },
  {
    id: "9y0yU5xWRU",
    name: "Currency",
    checked: false
  },
  {
    id: "kZf6CVjtq7",
    name: "D6 RPG",
    checked: false
  },
  {
    id: "vZsDDAdOoe",
    name: "Deck Building",
    checked: false
  },
  {
    id: "GsNGxZFNCK",
    name: "Deduction",
    checked: false
  },
  {
    id: "5kvyChnWuO",
    name: "Dexterity",
    checked: false
  },
  {
    id: "lVSHu9efHv",
    name: "Dice Building",
    checked: false
  },
  {
    id: "zw4KMn5rcD",
    name: "Dice Movement",
    checked: false
  },
  {
    id: "R0bGq4cAl4",
    name: "Dice Rolling",
    checked: false
  },
  {
    id: "wV5peB05xs",
    name: "Drafting",
    checked: false
  },
  {
    id: "KfTS5BwIsu",
    name: "Drawing",
    checked: false
  },
  {
    id: "Ouo00D4ka7",
    name: "Drinking",
    checked: false
  },
  {
    id: "yu3eas6v7A",
    name: "Engine Building",
    checked: false
  },
  {
    id: "hUn7uJHrYm",
    name: "Euro Game",
    checked: false
  },
  {
    id: "kS8npG0jl8",
    name: "Fighting",
    checked: false
  },
  {
    id: "S0O9ucexPS",
    name: "Flicking",
    checked: false
  },
  {
    id: "qu5BcGjAzk",
    name: "Grid Movement",
    checked: false
  },
  {
    id: "WPytek5P8l",
    name: "Hand Management",
    checked: false
  },
  {
    id: "i3xnwJ7VPV",
    name: "Hand-Eye Coordination",
    checked: false
  },
  {
    id: "6CyVyXRn1C",
    name: "Hex and Counter",
    checked: false
  },
  {
    id: "aQZ40lKv8O",
    name: "Hidden Movement",
    checked: false
  },
  {
    id: "Voqy2dgrIM",
    name: "Hidden Traitor",
    checked: false
  },
  {
    id: "DwmsVEvNVd",
    name: "Legacy",
    checked: false
  },
  {
    id: "MaXzmoZUoX",
    name: "Line Drawing",
    checked: false
  },
  {
    id: "UaLql58fua",
    name: "Live action and Role Playing game (Larp)",
    checked: false
  },
  {
    id: "ar56ACXDNe",
    name: "Mancala",
    checked: false
  },
  {
    id: "r6yIFvyXDD",
    name: "Memory",
    checked: false
  },
  {
    id: "U3zhCQH7Et",
    name: "Modular Board",
    checked: false
  },
  {
    id: "UHdPUeuqyZ",
    name: "Paper and Pencil",
    checked: false
  },
  {
    id: "GNtouC8NLm",
    name: "Partnerships",
    checked: false
  },
  {
    id: "3dFd0RwsY4",
    name: "Party Game",
    checked: false
  },
  {
    id: "9YdRn9J9oZ",
    name: "Pattern Building",
    checked: false
  },
  {
    id: "uZJS07nXF5",
    name: "Pattern Recognition",
    checked: false
  },
  {
    id: "BbTMRkwL0b",
    name: "Pick-up and Deliver",
    checked: false
  },
  {
    id: "BGrhzIN69D",
    name: "Player Elimination",
    checked: false
  },
  {
    id: "YyBg5CzHBF",
    name: "Player vs. Player",
    checked: false
  },
  {
    id: "MEAoOygZsA",
    name: "Point to Point Movement",
    checked: false
  },
  {
    id: "ea1eaPBQn8",
    name: "Pool Building",
    checked: false
  },
  {
    id: "hmipYN1R1I",
    name: "Press Your Luck",
    checked: false
  },
  {
    id: "24FWssBC3o",
    name: "Puzzle",
    checked: false
  },
  {
    id: "E9VKQ8uMSP",
    name: "Real Time",
    checked: false
  },
  {
    id: "Rt6V388y6M",
    name: "Resource Gathering",
    checked: false
  },
  {
    id: "j7x8jY4ay2",
    name: "Rock-Paper-Scissors",
    checked: false
  },
  {
    id: "EVeAdboGUA",
    name: "Role Playing",
    checked: false
  },
  {
    id: "gRlslORtpI",
    name: "Role Selection",
    checked: false
  },
  {
    id: "mGBzR68m8Z",
    name: "Roll / Spin and Move",
    checked: false
  },
  {
    id: "zIPRS41oiN",
    name: "Roll and Write",
    checked: false
  },
  {
    id: "FA6HjbhdNW",
    name: "Rondel",
    checked: false
  },
  {
    id: "asw8k7EIJI",
    name: "Route Building",
    checked: false
  },
  {
    id: "c6gkRM7rSy",
    name: "Route/Network Building",
    checked: false
  },
  {
    id: "RwgEONzIzc",
    name: "Secret Unit Deployment",
    checked: false
  },
  {
    id: "lA3KUtVFCy",
    name: "Set Collection",
    checked: false
  },
  {
    id: "eRe1jJCBFe",
    name: "Simulation",
    checked: false
  },
  {
    id: "DEvPj5twid",
    name: "Simultaneous Play",
    checked: false
  },
  {
    id: "za4PP1LH00",
    name: "Simultaneous action selection",
    checked: false
  },
  {
    id: "kuRNhFADjS",
    name: "Skirmish",
    checked: false
  },
  {
    id: "x3wVCq1HEP",
    name: "Social Deduction",
    checked: false
  },
  {
    id: "jbLrZb1xIb",
    name: "Stock Holding",
    checked: false
  },
  {
    id: "GUoWg3Mfh5",
    name: "Storytelling",
    checked: false
  },
  {
    id: "yDlgk7rXno",
    name: "Tactical Movement",
    checked: false
  },
  {
    id: "jCRze30VP1",
    name: "Tactics",
    checked: false
  },
  {
    id: "T8JEFYwoqy",
    name: "Take That",
    checked: false
  },
  {
    id: "9jnCsVuRat",
    name: "Teams",
    checked: false
  },
  {
    id: "8PN2HE86wg",
    name: "Tile Placement",
    checked: false
  },
  {
    id: "cXd5KaXXZo",
    name: "Time Track",
    checked: false
  },
  {
    id: "wEBvff5T5c",
    name: "Tower Defense",
    checked: false
  },
  {
    id: "AVY6EvSQTP",
    name: "Trading",
    checked: false
  },
  {
    id: "3GSQl800lk",
    name: "Trick-taking",
    checked: false
  },
  {
    id: "zzsE4jtI1b",
    name: "Variable Phase Order",
    checked: false
  },
  {
    id: "XM2FYZmBHH",
    name: "Variable Player Powers",
    checked: false
  },
  {
    id: "JYYdBW6UCE",
    name: "Voting",
    checked: false
  },
  {
    id: "yomuPpKFx4",
    name: "Wargame",
    checked: false
  },
  {
    id: "fBOTEBUAmV",
    name: "Worker Placement",
    checked: false
  }
];
