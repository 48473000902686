/*
 TODO: Stores this in a db and use a chron job or firestore function to update weekly from
 https://www.boardgameatlas.com/api/docs/game/categories
 */

export default [
  {
    id: "85OKv8p5Ow",
    name: "4x",
    checked: false
  },
  {
    id: "hBqZ3Ar4RJ",
    name: "Abstract",
    checked: false
  },
  {
    id: "KUBCKBkGxV",
    name: "Adventure",
    checked: false
  },
  {
    id: "20iDvpbh7A",
    name: "Age of Reason",
    checked: false
  },
  {
    id: "nWDac9tQzt",
    name: "Alternate History",
    checked: false
  },
  {
    id: "4mOtRRwSoj",
    name: "American West",
    checked: false
  },
  {
    id: "a8NM5cugJX",
    name: "Ancient",
    checked: false
  },
  {
    id: "MWoxgHrOJD",
    name: "Animals",
    checked: false
  },
  {
    id: "eFaACC6y2c",
    name: "Apocalyptic",
    checked: false
  },
  {
    id: "k0dglq5j6N",
    name: "Art",
    checked: false
  },
  {
    id: "QB4sEpx1Uu",
    name: "Aviation",
    checked: false
  },
  {
    id: "PinhJrhnxU",
    name: "Bluffing",
    checked: false
  },
  {
    id: "fW5vusE96B",
    name: "Campaign",
    checked: false
  },
  {
    id: "eX8uuNlQkQ",
    name: "Card Game",
    checked: false
  },
  {
    id: "HKaYVNIxAJ",
    name: "Children's Game",
    checked: false
  },
  {
    id: "ODWOjWAJj3",
    name: "City Building",
    checked: false
  },
  {
    id: "w8XD66FUZ2",
    name: "Civil War",
    checked: false
  },
  {
    id: "329DxyFL9D",
    name: "Civilization",
    checked: false
  },
  {
    id: "vXxLT0FDTZ",
    name: "Collectible Components",
    checked: false
  },
  {
    id: "G5kfqnPBP6",
    name: "Comic Book / Strip",
    checked: false
  },
  {
    id: "iTvYWFmD1c",
    name: "Conversation",
    checked: false
  },
  {
    id: "ge8pIhEUGE",
    name: "Cooperative",
    checked: false
  },
  {
    id: "Ef4oYLHNhI",
    name: "Cyberpunk",
    checked: false
  },
  {
    id: "bCBXJy9qDw",
    name: "Deduction",
    checked: false
  },
  {
    id: "bKrxqD9mYc",
    name: "Dexterity",
    checked: false
  },
  {
    id: "mavSOM8vjH",
    name: "Dice",
    checked: false
  },
  {
    id: "UuxiExraPF",
    name: "Dinosaurs",
    checked: false
  },
  {
    id: "We3MM46qBr",
    name: "Drinking",
    checked: false
  },
  {
    id: "ZEW7DPFAE6",
    name: "Dungeons & Dragons",
    checked: false
  },
  {
    id: "N0TkEGfEsF",
    name: "Economic",
    checked: false
  },
  {
    id: "B3NRLMK4xD",
    name: "Educational",
    checked: false
  },
  {
    id: "crxgUzJSEz",
    name: "Electronic",
    checked: false
  },
  {
    id: "gsekjrPJz0",
    name: "Environmental",
    checked: false
  },
  {
    id: "u5ZiYctU6T",
    name: "Espionage",
    checked: false
  },
  {
    id: "h8wfZG0j3I",
    name: "Eurogame",
    checked: false
  },
  {
    id: "v4SfYtS2Lr",
    name: "Expansion",
    checked: false
  },
  {
    id: "yq6hVlbM2R",
    name: "Exploration",
    checked: false
  },
  {
    id: "7rV11PKqME",
    name: "Family Game",
    checked: false
  },
  {
    id: "ctumBZyj5l",
    name: "Fan Made",
    checked: false
  },
  {
    id: "ZTneo8TaIO",
    name: "Fantasy",
    checked: false
  },
  {
    id: "Wr8uXcoR9p",
    name: "Farming",
    checked: false
  },
  {
    id: "upXZ8vNfNO",
    name: "Fighting",
    checked: false
  },
  {
    id: "zNxFBqBHXA",
    name: "Fishing",
    checked: false
  },
  {
    id: "3NDxCLUny4",
    name: "Flicking",
    checked: false
  },
  {
    id: "YrDuNj8lvr",
    name: "Food",
    checked: false
  },
  {
    id: "H9Ef643lYf",
    name: "Gay",
    checked: false
  },
  {
    id: "NR0vgCx5R7",
    name: "Halloween",
    checked: false
  },
  {
    id: "cAIkk5aLdQ",
    name: "Horror",
    checked: false
  },
  {
    id: "TYnxiuiI3X",
    name: "Humor",
    checked: false
  },
  {
    id: "zqFmdU4Fp2",
    name: "Industry/Manufacturing",
    checked: false
  },
  {
    id: "R7PTH00PmO",
    name: "Japan",
    checked: false
  },
  {
    id: "rrvd68LjOR",
    name: "Kickstarter",
    checked: false
  },
  {
    id: "XeYUw9159M",
    name: "Legacy",
    checked: false
  },
  {
    id: "nHZiDOXNla",
    name: "Luck",
    checked: false
  },
  {
    id: "pIMmuVYnQp",
    name: "Mafia",
    checked: false
  },
  {
    id: "POlqwScVxD",
    name: "Math",
    checked: false
  },
  {
    id: "ZhlfIPxYsw",
    name: "Mature / Adult",
    checked: false
  },
  {
    id: "c1AnMUJrTF",
    name: "Mecha",
    checked: false
  },
  {
    id: "AeWXMxbm91",
    name: "Medical",
    checked: false
  },
  {
    id: "QAYkTHK1Dd",
    name: "Medieval",
    checked: false
  },
  {
    id: "AujCle9cUq",
    name: "Memory",
    checked: false
  },
  {
    id: "FC6ElKI9tk",
    name: "Miniatures",
    checked: false
  },
  {
    id: "L6NUwNdblq",
    name: "Modern Warfare",
    checked: false
  },
  {
    id: "TJnR5obHsQ",
    name: "Movie Theme",
    checked: false
  },
  {
    id: "Sod2YBWMKi",
    name: "Movies / TV / Radio theme",
    checked: false
  },
  {
    id: "Kk70K0524Z",
    name: "Murder/Mystery",
    checked: false
  },
  {
    id: "MHkqIVxwtx",
    name: "Mythology",
    checked: false
  },
  {
    id: "IpcJzp0TVC",
    name: "Napoleonic",
    checked: false
  },
  {
    id: "vqZ5XzGWQD",
    name: "Nautical",
    checked: false
  },
  {
    id: "jZEDOpx07e",
    name: "Negotiation",
    checked: false
  },
  {
    id: "mWb5kHTAg1",
    name: "Ninja's",
    checked: false
  },
  {
    id: "rtslXnT90O",
    name: "Ninjas",
    checked: false
  },
  {
    id: "dO9HVl2TW7",
    name: "Novel-based",
    checked: false
  },
  {
    id: "X8J7RM6dxX",
    name: "Party Game",
    checked: false
  },
  {
    id: "9EIayX6n5a",
    name: "Pirates",
    checked: false
  },
  {
    id: "TKQncFVX74",
    name: "Political",
    checked: false
  },
  {
    id: "8Z7nWG2kOw",
    name: "Post-Apocalyptic",
    checked: false
  },
  {
    id: "5APB1MWk6X",
    name: "Post-Napoleonic",
    checked: false
  },
  {
    id: "YyszHun1HP",
    name: "Prehistoric",
    checked: false
  },
  {
    id: "ov6sEmlkiC",
    name: "Print & Play",
    checked: false
  },
  {
    id: "dAyk5NtNTV",
    name: "Prison Escape",
    checked: false
  },
  {
    id: "WVMOS3s2pb",
    name: "Puzzle",
    checked: false
  },
  {
    id: "c6nnwyDdnl",
    name: "Queer",
    checked: false
  },
  {
    id: "2Gu62aKdma",
    name: "RPG",
    checked: false
  },
  {
    id: "tQGLgwdbYH",
    name: "Racing",
    checked: false
  },
  {
    id: "PzWI2uaif0",
    name: "Real-time",
    checked: false
  },
  {
    id: "DRqeVkXWqX",
    name: "Religious",
    checked: false
  },
  {
    id: "nuHYRFmMjU",
    name: "Renaissance",
    checked: false
  },
  {
    id: "zyj9ZK3mHB",
    name: "Resource Management",
    checked: false
  },
  {
    id: "KSBdPfxs6F",
    name: "Roman Empire",
    checked: false
  },
  {
    id: "E5rYwP0Ybr",
    name: "Romance",
    checked: false
  },
  {
    id: "3B3QpKvXD3",
    name: "Sci-Fi",
    checked: false
  },
  {
    id: "c6ei4hkUxm",
    name: "Socialite",
    checked: false
  },
  {
    id: "VzyslQJGrG",
    name: "Solo / Solitaire",
    checked: false
  },
  {
    id: "0MdRqhkNpw",
    name: "Space Exploration",
    checked: false
  },
  {
    id: "Hc6vcim5DS",
    name: "Spies/Secret Agents",
    checked: false
  },
  {
    id: "hShsL2DktG",
    name: "Sports",
    checked: false
  },
  {
    id: "O0ogzwLUe8",
    name: "Strategy",
    checked: false
  },
  {
    id: "usFW8szGAq",
    name: "Superhero",
    checked: false
  },
  {
    id: "yHTeXNjln0",
    name: "Tech",
    checked: false
  },
  {
    id: "buDTYyPw4D",
    name: "Territory Building",
    checked: false
  },
  {
    id: "vCzpbYT7RU",
    name: "Theme Park",
    checked: false
  },
  {
    id: "JwHcKqxh33",
    name: "Trains",
    checked: false
  },
  {
    id: "CWYOF9xu7O",
    name: "Transportation",
    checked: false
  },
  {
    id: "TR4CiP8Huj",
    name: "Travel",
    checked: false
  },
  {
    id: "YGHGDjahKY",
    name: "Trivia",
    checked: false
  },
  {
    id: "djokexoK0U",
    name: "Video Game Theme",
    checked: false
  },
  {
    id: "ssZjU3HETz",
    name: "War",
    checked: false
  },
  {
    id: "jX8asGGR6o",
    name: "Wargame",
    checked: false
  },
  {
    id: "EHUBCITA3t",
    name: "Western",
    checked: false
  },
  {
    id: "rHvAx4hH2f",
    name: "Word Game",
    checked: false
  },
  {
    id: "wTLJSVEbm6",
    name: "World War I",
    checked: false
  },
  {
    id: "fl3TogdUzX",
    name: "World War II",
    checked: false
  },
  {
    id: "OlkGBmu4Va",
    name: "World War III",
    checked: false
  },
  {
    id: "FmGV9rVu1c",
    name: "Zombies",
    checked: false
  }
];
