import React from "react";
import { Link } from "@reach/router";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { ResponsiveContext, Grid, Heading, Text, Anchor } from "grommet";

const Games = () => {
  const firestore = useFirestore();
  const baseRef = firestore().collection("groups");
  const groups = useFirestoreCollectionData(baseRef, { idField: "id" });

  return (
    <div>
      <ResponsiveContext.Consumer>
        {size => (
          <Grid
            align="start"
            columns={size !== "small" && { count: "fill", size: "medium" }}
            gap="small"
          >
            {groups.map(group => {
              return (
                <Anchor as={Link} to={`groups/${group.id}`}>
                  <Heading level={4} margin={{ top: "none", bottom: "small" }}>
                    {group.name}
                  </Heading>
                  <Text color="dark-4">
                    Members: {Object.keys(group.members).length}
                  </Text>
                </Anchor>
              );
            })}
          </Grid>
        )}
      </ResponsiveContext.Consumer>
    </div>
  );
};

export default Games;
