import React from "react";
import { Anchor } from "grommet";
import { ScheduleNew } from "grommet-icons";

const AddToCalendar = ({ date, time, location, name }) => {
  let timeParts = time.split(":");

  if (time.includes("PM")) {
    timeParts[0] = (parseInt(timeParts[0], 0) + 12).toString();
  }

  const milTime = timeParts
    .join(":")
    .replace("PM", "")
    .replace("AM", "")
    .trim();

  const dateTime = new Date(`${date} ${milTime}`);
  let formattedTime = dateTime.toISOString();

  formattedTime = formattedTime.replace(/:/g, "");
  formattedTime = formattedTime.replace(/-/g, "");
  formattedTime = formattedTime.replace(".000Z", "Z");

  return (
    <Anchor
      href={`https://www.google.com/calendar/render?action=TEMPLATE&text=${name}&dates=${formattedTime}/${formattedTime}&location=${location}&sf=true&output=xml`}
      target="_blank"
    >
      <ScheduleNew size="small" />
    </Anchor>
  );
};

export default AddToCalendar;
