import React, { Suspense, useState } from "react";
import { useUser, useFirestore, useFirestoreCollectionData } from "reactfire";
import { Heading, Form, FormField, Text, Button, Box, Select } from "grommet";
import Loading from "../../components/Loading/Loading";
import styles from "./NewEvent.module.scss";

const FormFieldLabel = props => {
  const { required, label, ...rest } = props;
  return (
    <FormField
      label={
        required ? (
          <Box direction="row">
            <Text>{label}</Text>
          </Box>
        ) : (
          label
        )
      }
      required={required}
      {...rest}
    />
  );
};

const timeOptions = [
  "12:00 AM",
  "12:30 AM",
  "1:00 AM",
  "1:30 AM",
  "2:00 AM",
  "2:30 AM",
  "3:00 AM",
  "3:30 AM",
  "4:00 AM",
  "4:30 AM",
  "5:00 AM",
  "5:30 AM",
  "6:00 AM",
  "6:30 AM",
  "7:00 AM",
  "7:30 AM",
  "8:00 AM",
  "8:30 AM",
  "9:00 AM",
  "9:30 AM",
  "10:00 AM",
  "10:30 AM",
  "11:00 AM",
  "11:30 AM",
  "12:00 PM",
  "12:30 PM",
  "1:00 PM",
  "1:30 PM",
  "2:00 PM",
  "2:30 PM",
  "3:00 PM",
  "3:30 PM",
  "4:00 PM",
  "4:30 PM",
  "5:00 PM",
  "5:30 PM",
  "6:00 PM",
  "6:30 PM",
  "7:00 PM",
  "7:30 PM",
  "8:00 PM",
  "8:30 PM",
  "9:00 PM",
  "9:30 PM",
  "10:00 PM",
  "10:30 PM",
  "11:00 PM",
  "11:30 PM"
];

const NewEvent = ({ navigate }) => {
  const user = useUser();
  const [shouldHidePlaceholder, setShouldHidePlaceholder] = useState(false);
  const firestore = useFirestore();
  const baseRef = firestore().collection("groups");
  const groups = useFirestoreCollectionData(
    baseRef.where(`members.${user?.uid ?? ""}`, ">", ""),
    { idField: "id" }
  );

  if (!user) {
    navigate("/");
    return;
  }

  const handleOnSubmit = ({ value }) => {
    const { name, location, date, time, groupName } = value;
    const group = groups.find(({ name }) => name === value.groupName);
    const groupId = group?.id;

    const invited = {};
    Object.keys(group.members).forEach(member => {
      if (member === user.uid) {
        invited[user.uid] = "host";
      } else {
        invited[member] = "invited";
      }
    });

    const data = {
      name,
      location,
      date,
      time,
      groupName,
      groupId,
      invited: invited
    };

    firestore()
      .collection("events")
      .add(data)
      .then(docRef => {
        navigate(`/events/${docRef.id}`);
      });
  };

  if (groups.length === 0) {
    return (
      <div>
        <p>You must join a group before you can create a game night</p>
      </div>
    );
  }

  const groupOptions = groups.map(({ name }) => name);

  return (
    <Suspense fallback={<Loading />}>
      <Heading level={3}>New game night</Heading>

      <Form onSubmit={handleOnSubmit}>
        <Box margin={{ bottom: "large" }}>
          <FormFieldLabel
            name="name"
            label="Event name"
            placeholder={`${user.displayName}'s majestic night of friendship`}
            required
          />
        </Box>
        <Box margin={{ bottom: "large" }}>
          <FormFieldLabel
            name="location"
            label="Where's it happening?"
            placeholder={"555 Orion Spiral Arm Dr. Milky Way Galaxy 12345"}
            required
          />
        </Box>
        <Box margin={{ bottom: "large" }}>
          <Box pad={{ left: "medium" }}>
            <Text>When's it happening?</Text>
          </Box>
          <Box margin={{ top: "xsmall" }} direction="row">
            <div className={styles.dateWrapper}>
              <FormField
                required
                name="date"
                type="date"
                component={"input"}
                className={styles.date}
                onFocus={() => {
                  setShouldHidePlaceholder(true);
                }}
                onBlur={e => {
                  if (e.target.value === "") {
                    setShouldHidePlaceholder(false);
                  }
                }}
              />
              <Text
                className={`${styles.datePlaceholder} ${
                  shouldHidePlaceholder ? styles.hide : ""
                }`}
              >
                Date
              </Text>
            </div>
            <FormField
              required
              name="time"
              type="time"
              options={timeOptions}
              component={Select}
              placeholder="Time"
            />
          </Box>
        </Box>
        <Box margin={{ bottom: "large" }}>
          <FormFieldLabel
            placeholder="Select a group"
            options={groupOptions}
            name="groupName"
            label="Who's invited?"
            htmlFor="select"
            component={Select}
            required
          />
        </Box>

        <Button type="submit" label="Create" primary />
      </Form>
    </Suspense>
  );
};

export default NewEvent;
