import React, { Suspense } from "react";
import "firebase/firestore";
import "firebase/auth";
import "firebase/performance";
import firebaseConfig from "./firebaseConfig";
import { FirebaseAppProvider } from "reactfire";
import Loading from "../Loading/Loading";

const Firebase = ({ children }) => {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <Suspense fallback={<Loading />} maxDuration={200}>
        {children}
      </Suspense>
    </FirebaseAppProvider>
  );
};

export default Firebase;
