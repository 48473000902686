import React, { Suspense } from "react";
import { useFirestore, useFirestoreDoc } from "reactfire";
import { Heading, Box } from "grommet";
import FourOhFour from "../FourOhFour/FourOhFour";
import JoinLeaveGroup from "../../components/JoinLeaveGroup/JoinLeaveGroup";
import GroupMembers from "../../components/GroupMembers/GroupMembers";
import GroupGames from "../../components/GroupGames/GroupGames";
import Loading from "../../components/Loading/Loading";

const Group = ({ groupId }) => {
  const firestore = useFirestore();
  const baseRef = firestore()
    .collection("groups")
    .doc(groupId);
  const groupSnapshot = useFirestoreDoc(baseRef);
  const groupData = groupSnapshot.data();

  if (!groupSnapshot.exists) {
    return <FourOhFour />;
  }

  const memberUserIds = Object.keys(groupData.members);

  return (
    <Suspense fallback={<Loading />}>
      <Box flex overflow="auto" gap="medium">
        <Box direction="row" align="center" justify="between" overflow="auto">
          <Heading level={1}>{groupData.name}</Heading>
          <JoinLeaveGroup group={groupData} groupId={groupId} />
        </Box>
        <Box flex={false} direction="row-responsive" wrap>
          {memberUserIds.length > 0 && (
            <Box margin={{ bottom: "medium", right: "xlarge" }}>
              <Heading level={3}>Members</Heading>
              <GroupMembers members={groupData.members} />
            </Box>
          )}

          {memberUserIds.length > 0 && (
            <Box flex="grow" margin={{ bottom: "medium" }}>
              <Heading level={3}>Games</Heading>
              <GroupGames memberUserIds={memberUserIds} />
            </Box>
          )}
        </Box>
      </Box>
    </Suspense>
  );
};

export default Group;
