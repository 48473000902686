import React, { useState } from "react";
import {
  useFirestore,
  useUser,
  useFirestoreCollectionData,
  useFirebaseApp
} from "reactfire";
import { Search } from "grommet-icons";
import { Box, TextInput, Text } from "grommet";
import { useDebouncedCallback } from "use-debounce";
import camelcaseKeys from "camelcase-keys";
import GameCard from "../GameCard/GameCard";
import GamesGrid from "../GamesGrid/GamesGrid";
import Loading from "../Loading/Loading";
import getCategories from "../../util/getCategories";
import getMechanics from "../../util/getMechanics";

const Games = () => {
  const [games, setGames] = useState([]);
  const [searchStatus, setSearchStatus] = useState("initial");
  const firestore = useFirestore();
  const firebaseApp = useFirebaseApp();
  const baseRef = firestore().collection("games");
  const user = useUser();
  const userGames = useFirestoreCollectionData(
    baseRef.where("userIds", "array-contains", user.uid)
  );

  const [debounceSearch] = useDebouncedCallback(async value => {
    setGames([]);

    if (value === "") {
      return setSearchStatus("initial");
    }

    setSearchStatus("pending");

    const response = await fetch(
      `https://www.boardgameatlas.com/api/search?name=${value}&limit=15&client_id=J1na72RCPs`
    );
    const gamesJson = await response.json();
    setGames(camelcaseKeys(gamesJson.games));
    setSearchStatus("done");
  }, 400);

  const handleOnGameSearch = evt => {
    const value = evt?.target?.value;
    debounceSearch(value);
  };

  const handleAddGame = ({
    name = "",
    images = {},
    descriptionPreview = "",
    minPlayers = 0,
    maxPlayers = 0,
    averageUserRating = 0,
    officialUrl = "",
    rulesUrl = "",
    minPlaytime = 0,
    maxPlaytime = 0,
    categories,
    mechanics,
    id
  }) => {
    if (!id) {
      return;
    }

    const foundCategories = getCategories(categories);
    const foundMechanics = getMechanics(mechanics);

    baseRef.doc(id).set(
      {
        name,
        imageSmall: images.small,
        imageMedium: images.medium,
        imageLarge: images.large,
        descriptionPreview,
        minPlayers,
        maxPlayers,
        averageUserRating,
        officialUrl,
        rulesUrl,
        minPlaytime,
        maxPlaytime,
        userIds: firebaseApp.firestore.FieldValue.arrayUnion(user.uid),
        mechanics: foundMechanics,
        categories: foundCategories
      },
      { merge: true }
    );
  };

  if (!user.uid) {
    return false;
  }

  return (
    <div>
      <Box fill align="center" pad={{ vertical: "medium" }}>
        <Box
          width="large"
          direction="row"
          align="center"
          pad={{ horizontal: "small", vertical: "xsmall" }}
          round="small"
          border={{
            side: "all"
          }}
        >
          <Search color="brand" />
          <TextInput
            type="search"
            plain
            onChange={handleOnGameSearch}
            placeholder="Find your game..."
          />
        </Box>
      </Box>

      {searchStatus === "pending" && <Loading inline />}

      {searchStatus === "done" && games.length === 0 && (
        <Text>No dice. Try again.</Text>
      )}

      <GamesGrid>
        {games.map(game => {
          const hasGame = userGames.some(
            userGame => userGame.name === game.name
          );

          return (
            <GameCard
              {...game}
              key={game.id}
              onAddClick={handleAddGame}
              hasGame={hasGame}
            />
          );
        })}
      </GamesGrid>
    </div>
  );
};

export default Games;
