import React from "react";
import { Text, Box } from "grommet";
import { Yoga } from "grommet-icons";

const FourOhFour = ({ navigate, ...rest }) => {
  return (
    <Box justify="center" align="center" pad="xlarge" margin="xlarge">
      <Yoga size="xlarge" color="brand" />
      <Text margin="medium">
        Nothing to see here except this man doing yoga.
      </Text>
    </Box>
  );
};

export default FourOhFour;
