import React from "react";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import GameCard from "../GameCard/GameCard";
import GamesGrid from "../GamesGrid/GamesGrid";
import GameFilter from "../GameFilter/GameFilter";

const GroupGames = ({ memberUserIds }) => {
  const firestore = useFirestore();
  const baseRef = firestore().collection("games");
  const games = useFirestoreCollectionData(
    baseRef.where("userIds", "array-contains-any", memberUserIds.slice(0, 10)),
    { idField: "id" }
  );

  return (
    <GameFilter games={games}>
      {filteredGames => (
        <GamesGrid>
          {filteredGames.map(game => {
            return <GameCard {...game} key={game.id} />;
          })}
        </GamesGrid>
      )}
    </GameFilter>
  );
};
export default GroupGames;
