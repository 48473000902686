import React from "react";
import { Text, Box, ResponsiveContext } from "grommet";
import * as moment from "moment";

const EventDate = ({ date, time }) => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box width={size !== "small" ? "280px" : "100%"} flex justify="between">
          <Box
            align={size !== "small" ? "end" : "center"}
            pad={{ horizontal: "medium", vertical: "small" }}
            background="black"
            width="100%"
            height="100%"
            justify="center"
          >
            <Text color="white" size="xlarge" margin={{ bottom: "5px" }}>
              {moment(date).format("MMM Do")}
            </Text>
            <Text color="white" size="medium">
              {moment(date).format("YYYY")}
            </Text>
          </Box>

          <Box
            background="dark-1"
            width="100%"
            align={size !== "small" ? "end" : "center"}
            pad="medium"
            justify="center"
          >
            <Text color="light-2" size="large">
              {time}
            </Text>
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default EventDate;
