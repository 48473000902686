import React from "react";
import { useUser } from "reactfire";
import { Image, Text, Box } from "grommet";

const UserInfo = () => {
  const user = useUser();

  const { photoURL, displayName, email } = user;

  return (
    <div>
      <Box height="small" width="small" margin={{ bottom: "small" }}>
        <Image src={photoURL} fit="cover" />
      </Box>
      <Box>
        <Text size="large" color="dark-1" margin={{ vertical: "none" }}>
          {displayName}
        </Text>
      </Box>
      <Box>
        <Text size="small" color="dark-3" margin={{ vertical: "none" }}>
          {email}
        </Text>
      </Box>
    </div>
  );
};

export default UserInfo;
