import React, { Suspense } from "react";
import { useFirestore, useFirestoreDoc, useUser } from "reactfire";
import { Heading, ResponsiveContext, Box, Anchor } from "grommet";
import FourOhFour from "../FourOhFour/FourOhFour";
import UserCards from "../../components/UserCards/UserCards";
import OnTheTable from "../../components/OnTheTable/OnTheTable";
import AcceptDeclineEvent from "../../components/AcceptDeclineEvent/AcceptDeclineEvent";
import getKeyByValue, { getKeysByValue } from "../../util/getKeyByValue";
import Loading from "../../components/Loading/Loading";
import Tag from "../../components/Tag/Tag";
import DeleteEvent from "../../components/DeleteEvent/DeleteEvent";
import EventDate from "../../components/EventDate/EventDate";
import AddToCalendar from "../../components/AddToCalendar/AddToCalendar";
import styles from "./Event.module.scss";

const Event = ({ eventId, navigate }) => {
  const firestore = useFirestore();
  const baseRef = firestore()
    .collection("events")
    .doc(eventId);
  const eventSnapshot = useFirestoreDoc(baseRef);
  const eventData = eventSnapshot.data();

  if (!eventSnapshot.exists) {
    return <FourOhFour />;
  }

  return (
    <Suspense fallback={<Loading />} maxDuration={1000}>
      <EventView data={eventData} baseRef={baseRef} navigate={navigate} />
    </Suspense>
  );
};

const EventView = ({ data, baseRef, navigate }) => {
  const firestore = useFirestore();
  const groupsBaseRef = firestore()
    .collection("groups")
    .doc(data.groupId);

  const groupSnapshot = useFirestoreDoc(groupsBaseRef);
  const groupData = groupSnapshot.data();
  const host = getKeyByValue(data.invited, "host");
  const accepted = getKeysByValue(data.invited, "accepted");
  const declined = getKeysByValue(data.invited, "declined");
  const invited = Object.keys(groupData.members).filter(
    id => id !== host && !accepted.includes(id) && !declined.includes(id)
  );

  const user = useUser();
  const uid = user?.uid;
  const invitationStatus = data.invited[uid];
  const isHost = invitationStatus === "host";
  const isInvited =
    invitationStatus && Object.keys(groupData.members).includes(uid);

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box flex gap="medium">
          <Box
            flex={false}
            overflow="none"
            background="black"
            margin={{ horizontal: size !== "small" ? "-6rem" : "-1rem" }}
            pad={{ right: size !== "small" ? "6rem" : "0" }}
            className={styles.header}
            direction={size !== "small" ? "row" : "column"}
            justify="between"
            align={size !== "small" ? "iniital" : "center"}
          >
            <Box
              direction={size !== "small" ? "row" : "column"}
              width={size !== "small" ? "iniital" : "100%"}
            >
              <EventDate date={data.date} time={data.time} />
              <Box justify="between">
                <Box
                  pad={{ horizontal: "medium", vertical: "small" }}
                  height="100%"
                  justify="center"
                >
                  <Heading level={2} margin={{ bottom: "5px", top: "none" }}>
                    {data.name}
                  </Heading>
                  <Box direction="row">
                    <Anchor
                      href={`https://www.google.com/maps/place/${data.location}`}
                      target="_blank"
                      margin={{ right: "small" }}
                    >
                      {data.location}
                    </Anchor>
                    <AddToCalendar
                      name={data.name}
                      time={data.time}
                      location={data.location}
                      date={data.date}
                    />
                  </Box>
                </Box>

                <Box pad="medium" justify="center">
                  <UserCards
                    userIds={[host]}
                    size="50px"
                    meta={<Tag color="pink" text="Host" />}
                  />
                </Box>
              </Box>
            </Box>

            <Box direction="row" justify="between" margin={{ top: "medium" }}>
              <Box margin={{ vertical: "medium" }}>
                {!isHost && isInvited && (
                  <AcceptDeclineEvent
                    baseRef={baseRef}
                    invitationStatus={invitationStatus}
                  />
                )}
                {isHost && (
                  <DeleteEvent
                    baseRef={baseRef}
                    invited={data.invited}
                    navigate={navigate}
                  />
                )}
              </Box>
            </Box>
          </Box>
          <Box flex={false} direction="row-responsive" wrap>
            <Box margin={{ bottom: "medium", right: "xlarge" }}>
              {accepted.length > 0 && (
                <>
                  <Heading margin={{ bottom: "medium" }} level={4}>
                    Going
                  </Heading>
                  <UserCards userIds={accepted} />
                </>
              )}
              {declined.length > 0 && (
                <>
                  <Heading margin={{ vertical: "medium" }} level={4}>
                    No-ing
                  </Heading>
                  <UserCards userIds={declined} />
                </>
              )}
              {invited.length > 0 && (
                <>
                  <Heading margin={{ vertical: "medium" }} level={4}>
                    Invited
                  </Heading>
                  <UserCards userIds={invited} />
                </>
              )}
            </Box>
            <Box flex="grow" margin={{ bottom: "medium" }}>
              <OnTheTable
                userIds={accepted.concat(host)}
                time={data.time}
                votedGames={data.votedGames ?? {}}
                invitationStatus={invitationStatus}
                eventBaseRef={baseRef}
              />
            </Box>
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default Event;
