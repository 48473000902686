import React from "react";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import UserCard from "../UserCard/UserCard";

const UserCards = ({ userIds, size, meta }) => {
  const firestore = useFirestore();
  const baseRef = firestore().collection("users");
  const userData = useFirestoreCollectionData(
    baseRef.where("uid", "in", userIds)
  );

  return (
    <div>
      {userData.map(({ displayName, photoURL, uid }) => (
        <UserCard
          photoURL={photoURL}
          displayName={displayName}
          key={uid}
          size={size}
          meta={meta}
        />
      ))}
    </div>
  );
};
export default UserCards;
