import React from "react";
import Avatar from "../Avatar/Avatar";
import styles from "./UserCard.module.scss";

const UserCard = ({ photoURL, displayName, meta, size }) => {
  return (
    <div className={styles.root}>
      <Avatar url={photoURL} name={displayName} size={size} />
      <div className={styles.memberInfo}>
        <p className={styles.displayName}>{displayName}</p>
        {meta}
      </div>
    </div>
  );
};
export default UserCard;
