import React from "react";
import { Box } from "grommet";

const Avatar = ({ name, url, size, ...props }) => (
  <Box
    a11yTitle={`${name} avatar`}
    height="avatar"
    width="avatar"
    round="small"
    background={`url(${url})`}
    style={{
      height: size,
      width: size
    }}
    {...props}
  />
);

Avatar.defaultProps = {
  size: "32px"
};

export default Avatar;
