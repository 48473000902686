import React from "react";
import UserCard from "../UserCard/UserCard";
import Tag from "../Tag/Tag";

const GroupMember = ({ photoURL, displayName, role }) => {
  let props = {
    text: role
  };

  if (role === "founder") {
    props.color = "pink";
  }

  return (
    <UserCard
      photoURL={photoURL}
      displayName={displayName}
      meta={<Tag {...props} />}
    />
  );
};
export default GroupMember;
