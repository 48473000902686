import React from "react";
import { Link } from "@reach/router";
import { Heading, Text, Anchor, Box } from "grommet";
import * as moment from "moment";

const Events = ({ event }) => {
  return (
    <Anchor as={Link} to={`events/${event.id}`}>
      <Box
        overflow="hidden"
        border={{
          color: "light-5",
          size: "xsmall"
        }}
        pad="small"
      >
        <Heading level={4} margin={{ top: "none", bottom: "small" }}>
          {event.name}
        </Heading>
        <Text color="dark-4">
          {moment(event.date).format("MMM Do YYYY")} @ {event.time}
        </Text>
      </Box>
    </Anchor>
  );
};

export default Events;
