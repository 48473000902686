import React from "react";
import styles from "./Tag.module.scss";

const Tag = ({ text, color }) => {
  return <p className={`${styles.root} ${styles[color]}`}>{text}</p>;
};

Tag.defaultProps = {
  color: "default"
};

export default Tag;
