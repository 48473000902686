import React from "react";
import { useFirestore, useFirestoreCollectionData, useUser } from "reactfire";
import { ResponsiveContext, Grid, Heading, Box } from "grommet";
import EventCard from "../EventCard/EventCard";

const Events = ({ status }) => {
  const user = useUser();
  const firestore = useFirestore();
  const eventsBaseHref = firestore().collection("events");
  const groupsBaseHref = firestore().collection("groups");

  const groups = useFirestoreCollectionData(
    groupsBaseHref.where(`members.${user.uid}`, ">", ""),
    { idField: "id" }
  );

  const groupIds = groups.map(({ id }) => id);

  console.log(groupIds);

  const events = useFirestoreCollectionData(
    eventsBaseHref.where(
      "groupId",
      "in",
      groupIds.length > 0 ? groupIds : ["0"]
    ),
    { idField: "id" }
  );

  const acceptedEvents = events.filter(
    ({ invited }) =>
      invited[user.uid] === "accepted" || invited[user.uid] === "host"
  );

  const invitedToEvents = events.filter(
    ({ invited }) =>
      invited[user.uid] !== "accepted" &&
      invited[user.uid] !== "host" &&
      invited[user.uid] !== "declined"
  );

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <>
          <Box margin={{ bottom: "medium" }}>
            <Heading level={2} margin={{ vertical: "small" }}>
              Game nights you're attending
            </Heading>
            <Grid
              align="start"
              columns={size !== "small" && { count: "fill", size: "medium" }}
              gap="small"
            >
              {acceptedEvents.map(event => {
                return <EventCard key={event.id} event={event} />;
              })}
            </Grid>
          </Box>
          <Box margin={{ bottom: "medium" }}>
            <Heading level={2} margin={{ vertical: "small" }}>
              Game night invites
            </Heading>
            <Grid
              align="start"
              columns={size !== "small" && { count: "fill", size: "medium" }}
              gap="small"
            >
              {invitedToEvents.map(event => {
                return <EventCard key={event.id} event={event} />;
              })}
            </Grid>
          </Box>
        </>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default Events;
