import React from "react";
import { Button, Box, Text } from "grommet";
import { Like } from "grommet-icons";
import { useUser, useFirebaseApp } from "reactfire";
// import styles from "./GameVote.module.scss";

const GameVote = ({ votes, invitationStatus, eventBaseRef, gameId }) => {
  const user = useUser();
  const firebaseApp = useFirebaseApp();
  const uid = user?.uid;
  const voted = votes.includes(uid);
  const votesCount = votes.length;
  const voteMessage = votesCount === 1 ? "wants to play" : "want to play";

  const canVote =
    (invitationStatus === "accepted" || invitationStatus === "host") &&
    eventBaseRef;

  const handleOnClick = () => {
    if (voted) {
      return eventBaseRef.set(
        {
          votedGames: {
            [gameId]: firebaseApp.firestore.FieldValue.arrayRemove(uid)
          }
        },
        { merge: true }
      );
    } else {
      return eventBaseRef.set(
        {
          votedGames: {
            [gameId]: firebaseApp.firestore.FieldValue.arrayUnion(uid)
          }
        },
        { merge: true }
      );
    }
  };

  return (
    <Box direction="row" align="center" margin={{ vertical: "small" }}>
      {canVote && (
        <Button
          icon={<Like size="small" />}
          onClick={handleOnClick}
          primary={voted}
          plain={false}
          margin={{ right: "small" }}
        />
      )}
      <Text size="small">
        {votesCount} {voteMessage}
      </Text>
    </Box>
  );
};

export default GameVote;
