import React from "react";
import { ResponsiveContext, Grid } from "grommet";

const GamesGrid = ({ children }) => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Grid
          align="start"
          columns={size !== "small" && { count: "fill", size: "medium" }}
          gap="small"
        >
          {children}
        </Grid>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default GamesGrid;
