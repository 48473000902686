import React, { useState, useMemo } from "react";
import { TextInput, Box, Select, Button, ResponsiveContext } from "grommet";

const GamesGrid = ({ games, children }) => {
  const [search, setSearch] = useState("");
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [filteredMechanics, setFilteredMechanics] = useState([]);
  const filters = useMemo(() => {
    let categories = [];
    let mechanics = [];
    games.forEach(game => {
      if (game.categories) {
        game.categories.forEach(({ name }) => {
          if (!categories.includes(name)) {
            categories.push(name);
          }
        });
      }

      if (game.mechanics) {
        game.mechanics.forEach(({ name }) => {
          if (!mechanics.includes(name)) {
            mechanics.push(name);
          }
        });
      }
    });

    return { categories, mechanics };
  }, [games]);

  const handleSearchOnChange = evt => {
    setSearch(evt.target.value.toLowerCase());
  };

  const handleCategoriesOnChange = ({ value }) => {
    setFilteredCategories(value);
  };

  const handleMechanicsOnChange = ({ value }) => {
    setFilteredMechanics(value);
  };

  const handleOnClickClear = () => {
    setSearch("");
    setFilteredCategories([]);
    setFilteredMechanics([]);
  };

  const searchedResults = games.filter(
    ({ name, categories = [], mechanics = [] }) => {
      let matchesCategoriesFilter = true;
      let matchesMechanicsFilter = true;

      if (filteredCategories?.length > 0) {
        matchesCategoriesFilter =
          categories.filter(({ name }) => filteredCategories.includes(name))
            .length === filteredCategories.length;
      }

      if (filteredMechanics?.length > 0) {
        matchesMechanicsFilter =
          mechanics.filter(({ name }) => filteredMechanics.includes(name))
            .length === filteredMechanics.length;
      }

      return (
        name.toLowerCase().includes(search) &&
        matchesCategoriesFilter &&
        matchesMechanicsFilter
      );
    }
  );

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <>
          <Box
            direction={size !== "small" ? "row" : "column"}
            align={size !== "small" ? "center" : "start"}
            pad="small"
            background="light-1"
            margin={{ bottom: "small" }}
          >
            <Box
              width={size !== "small" ? "medium" : "large"}
              margin={{
                right: "small",
                bottom: size !== "small" ? "none" : "medium"
              }}
            >
              <TextInput
                value={search}
                onChange={handleSearchOnChange}
                placeholder="Filter games"
              />
            </Box>
            <Box direction="row">
              <Select
                options={filters.categories}
                dropHeight="medium"
                multiple
                value={filteredCategories}
                placeholder="Categories"
                onChange={handleCategoriesOnChange}
                margin={{ right: "small" }}
              />
              <Box direction="row" align="center" justify="center" gap="small">
                <Select
                  options={filters.mechanics}
                  dropHeight="medium"
                  value={filteredMechanics}
                  multiple
                  placeholder="Mechanics"
                  onChange={handleMechanicsOnChange}
                />
                <Button
                  onClick={handleOnClickClear}
                  disabled={
                    filteredCategories.length === 0 &&
                    filteredMechanics.length === 0 &&
                    !search
                  }
                  plain
                  label="Clear"
                  size="xsmall"
                />
              </Box>
            </Box>
          </Box>
          {children(searchedResults)}
        </>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default GamesGrid;
