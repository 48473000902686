import React from "react";
import { Button, Box } from "grommet";
import { Trash } from "grommet-icons";
import { AuthCheck } from "reactfire";

const DeleteEvent = ({ baseRef, navigate }) => {
  const handleDeleteEvent = () => {
    baseRef.delete().then(() => navigate("/"));
  };

  return (
    <AuthCheck fallback={<div />}>
      <Box direction="row">
        <Button
          icon={<Trash size="small" />}
          color="status-critical"
          label="Delete event"
          onClick={handleDeleteEvent}
        />
      </Box>
    </AuthCheck>
  );
};

export default DeleteEvent;
