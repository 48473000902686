import React from "react";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { Heading } from "grommet";
import GameCard from "../GameCard/GameCard";
import GamesGrid from "../GamesGrid/GamesGrid";
import GameFilter from "../GameFilter/GameFilter";

const OnTheTable = ({
  userIds,
  votedGames,
  invitationStatus,
  eventBaseRef
}) => {
  const firestore = useFirestore();
  const baseRef = firestore().collection("games");
  const games = useFirestoreCollectionData(
    baseRef.where("userIds", "array-contains-any", userIds),
    { idField: "id" }
  );

  const gamesWithEnoughPlayers = [];
  const gamesWithOutEnoughPlayers = games.filter(game => {
    if (userIds.length < game.minPlayers) {
      return true;
    } else {
      gamesWithEnoughPlayers.push(game);
    }
  });

  return (
    <div>
      {gamesWithEnoughPlayers.length > 0 && (
        <GameFilter games={gamesWithEnoughPlayers}>
          {data => (
            <>
              <Heading margin={{ bottom: "medium" }} level={4}>
                Coming to the table
              </Heading>
              <GamesGrid>
                {data.map(game => {
                  let votes = votedGames[game.id] ?? [];
                  return (
                    <GameCard
                      {...game}
                      key={game.id}
                      votes={votes}
                      invitationStatus={invitationStatus}
                      eventBaseRef={eventBaseRef}
                    />
                  );
                })}
              </GamesGrid>
            </>
          )}
        </GameFilter>
      )}

      {gamesWithOutEnoughPlayers.length > 0 && (
        <>
          <Heading margin={{ bottom: "medium" }} level={4}>
            Need more players
          </Heading>
          <GamesGrid>
            {gamesWithOutEnoughPlayers.map(game => {
              return <GameCard {...game} key={game.id} />;
            })}
          </GamesGrid>
        </>
      )}
    </div>
  );
};
export default OnTheTable;
