import React from "react";
import { Router } from "@reach/router";
import { Grommet, ResponsiveContext } from "grommet";
import { AuthCheck } from "reactfire";
import Firebase from "./components/Firebase/Firebase";
import { Main } from "grommet";
import Header from "./components/Header/Header";
import Home from "./pages/Home";
import Profile from "./pages/Profile/Profile";
import Group from "./pages/Group/Group";
import NewEvent from "./pages/NewEvent/NewEvent";
import Event from "./pages/Event/Event";
import Login from "./components/Login/Login";
import BetaCheck from "./components/BetaCheck/BetaCheck";

const theme = {
  global: {
    font: {
      family: "'IBM Plex Sans', sans-serif",
      size: "18px",
      height: "20px;"
    },
    colors: {
      brand: "#7D4DFF"
    }
  },
  button: {
    border: {
      radius: "4px",
      width: "1px"
    }
  },
  tab: {
    color: "dark-3",
    active: {
      color: "brand"
    }
  }
};

const App = () => {
  return (
    <Grommet theme={theme}>
      <Firebase>
        <Header />
        <ResponsiveContext.Consumer>
          {size => (
            <Main
              pad={
                size !== "small"
                  ? { vertical: "xsmall", horizontal: "xlarge" }
                  : { vertical: "xsmall", horizontal: "medium" }
              }
            >
              <AuthCheck fallback={<Login />}>
                <BetaCheck>
                  <Router>
                    <Home path="/" />
                    <Profile path="/profile" />
                    <Group path="/groups/:groupId" />
                    <NewEvent path="/events/new" />
                    <Event path="/events/:eventId" />
                  </Router>
                </BetaCheck>
              </AuthCheck>
            </Main>
          )}
        </ResponsiveContext.Consumer>
      </Firebase>
    </Grommet>
  );
};

export default App;
