import React, { Suspense } from "react";
import { useUser, useAuth } from "reactfire";
import UserMenu from "../UserMenu/UserMenu";

const signOut = auth =>
  auth()
    .signOut()
    .then(() => console.log("signed out"));

const UserDetails = ({ user }) => {
  const auth = useAuth();
  return <UserMenu user={user} onSignOut={() => signOut(auth)} />;
};

const FirebaseAuthStateButton = () => {
  const user = useUser();
  return user ? <UserDetails user={user} /> : null;
};

const AuthButton = props => {
  return (
    <Suspense fallback={<p>loading...</p>}>
      <FirebaseAuthStateButton />
    </Suspense>
  );
};

export default AuthButton;
