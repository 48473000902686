import React, { Suspense } from "react";
import { useUser, useFirestore, useFirestoreDoc } from "reactfire";
import { Box, Heading } from "grommet";
import Loading from "../Loading/Loading";
import { Alert } from "grommet-icons";

const BetaCheck = ({ children }) => {
  const user = useUser();
  const firestore = useFirestore();
  const baseRef = firestore()
    .collection("users")
    .doc(user?.uid);
  const userData = useFirestoreDoc(baseRef).data();

  return (
    <Suspense fallback={<Loading />}>
      {userData.betaEnabled ? (
        children
      ) : (
        <Box justify="center" align="center" height="large">
          <Box
            direction="row"
            align="center"
            border={{
              color: "light-5",
              size: "xsmall"
            }}
            width="600px"
            pad="large"
            round="xsmall"
          >
            <Alert />
            <Heading margin="medium" level={4}>
              Sorry, to access the closed beta, you need an invite.
            </Heading>
          </Box>
        </Box>
      )}
    </Suspense>
  );
};

export default BetaCheck;
